<template>
  <div class="back">
    <div class="flex align-center justify-between">
      <div class="flex align-center">
        <div class="return-icon" @click="isShowSearch = !isShowSearch">
          <img src="@/assets/images/smartElderly/return.png" class="return" :class="{'rotate':!isShowSearch}">
        </div>
        <div v-if="isShowSearch" class="input">
          <el-input v-model="keyword" placeholder="请输入姓名或电话搜索" class="width-240 text-16"/>
          <img src="@/assets/images/smartElderly/search.png" class="search" @click="getList(1)">
        </div>
      </div>
      <div class="export-icon" @click.stop="exportData">导出</div>
    </div>
    <div class="flex flex-wrap content-list" @scroll="searchListScroll">
      <div v-for="(item,index) in list" :key="index" class="content-one">
        <div class="flex align-start">
          <img :src="item.oldPersonPhotoUrl" class="photo-url">
          <div class="flex flex-column" style="flex: 1;margin-right: 2rem">
            <div class="name">{{ item.oldPersonName }}</div>
            <div class="desc">年龄：{{ item.age }}</div>
            <div class="desc">电话号码：{{ item.contract }}</div>
            <div class="desc">人员类别：{{ item.personTypeNames }}</div>
            <!--<div class="desc" >地址：{{ item.address }}</div> classbao 临时-->
          </div>
        </div>
        <div class="line" v-if="item.showStatus"></div>
        <div class="zhineng" v-if="item.showStatus && item.leaveBed !== null">状态
          <span v-if="!item.leaveBed" class="zaichuang">在床</span>
          <span v-else class="lichuang">离床</span>
          <span v-if="!item.leaveBed && item.sleep" class="zaichuang">睡眠</span>
          <span v-if="!item.leaveBed && item.snore" class="zaichuang">打鼾</span>
          <span v-if="!item.leaveBed && item.moveBody" class="zaichuang">体动</span>
        </div>
        <div class="device-list flex flex-wrap device-status-1" v-if="item.showStatus">
          <div class="device-one" v-if="item.SHeart !== null">心率：{{ item.SHeart }}</div>
          <div class="device-one" v-if="item.SBreath !== null">呼吸 ：{{ item.SBreath }}</div>
          <div class="device-one" v-if="item.b !== null">电量：{{ item.b }}</div>
        </div>
        <div class="line"/>
        <div class="zhineng">智能设备</div>
        <div
          :ref="'device-list-'+index"
          class="device-list flex flex-wrap text-cut-one"
          :class="{'height-auto':!item.showArrowDown}"
        >
          <div
            v-for="(deviceItem,deviceIndex) in item.deviceNameAndStatusList"
            :key="deviceIndex"
            class="device-one"
            :class="'device-status-'+deviceItem.status"
            @click="showLayer(item.oldPersonName,item.oldPersonId,deviceItem.name)"
          >{{ deviceItem.name }}
          </div>
        </div>
        <div
          v-if="item.showArrow"
          class="flex align-center justify-center"
          style="margin-bottom: 0.5rem"
          @click="changeArrow(index)"
        >
          <img v-if="item.showArrowDown" src="@/assets/images/smartElderly/drop-down.png" class="drop-down">
          <img v-else src="@/assets/images/smartElderly/retract.png" class="drop-down">
        </div>
      </div>
    </div>

    <el-dialog title="" v-if="layerYgShow" :visible.sync="layerYgShow" width="75.7rem" style="font-size: 1.6rem">
      <div class="flex align-center justify-center flex-column color-65">
        {{ layerParam.oldPersonName }}-{{ layerParam.deviceName }}
      </div>
      <div class="layer-content-list" @scroll="layerListScroll">
        <div v-for="(item,index) in layerList" :key="index" class="layer-content-one color-65">
          <div>{{ item.currDate }}</div>
          <div class="layer-line margin-top-2"/>
          <div v-for="(listItem,listIndex) in item.itemList" :key="listIndex" class="flex align-center margin-top-2">
            <div>{{ listItem.reportTime }}</div>
            <div class="margin-left-2 color-ec">{{ listItem.message }}</div>
          </div>
        </div>
      </div>
      <div class="flex flex-reverse">
        <div class="close-button" @click="layerYgShow = false">关闭</div>
      </div>
    </el-dialog>

    <el-dialog title="" v-if="layerSosShow" :visible.sync="layerSosShow" width="75.7rem" style="font-size: 1.6rem">
      <div class="flex align-center justify-center flex-column color-65">
        {{ layerParam.oldPersonName }}-{{ layerParam.deviceName }}
      </div>
      <div class="layer-content-list" @scroll="layerListScroll">
        <div v-for="(item,index) in layerList" :key="index" class="layer-content-one color-65">
          <div class="color-ec">{{ item.sosTime }}&nbsp;&nbsp;&nbsp;&nbsp;{{ item.sosContent }}</div>
          <div class="margin-top-2 color-65">设备IMEI：{{ item.deviceId }}</div>
          <div class="margin-top-2 color-65">手机号：{{ item.deviceId }}</div>
          <div class="margin-top-2 color-65">经纬度：{{ item.sosLatitude }}，{{ item.sosLongitude }}</div>
          <div class="margin-top-2 color-65">地址：{{ item.address }}</div>
        </div>
      </div>
      <div class="flex flex-reverse">
        <div class="close-button" @click="layerSosShow = false">关闭</div>
      </div>
    </el-dialog>

    <el-dialog title="" v-if="layerSbShow" :visible.sync="layerSbShow" width="75.7rem" style="font-size: 1.6rem">
      <div class="flex align-center justify-center flex-column color-65">
        {{ layerParam.oldPersonName }}-{{ layerParam.deviceName }}
      </div>
      <div class="layer-content-list" @scroll="layerListScroll">
        <div v-for="(item,index) in layerList" :key="index" class="layer-content-one color-65">
          <div class="color-13">{{ item.reportTime }}</div>
          <div v-if="item.dzwl != null" class="margin-top-2 color-65">电子围栏：{{ item.dzwl }}</div>
          <div v-if="item.cwl != null" class="margin-top-2 color-65">出围栏：{{ item.cwl }}</div>
          <div v-if="item.jwl != null" class="margin-top-2 color-65">进围栏：{{ item.jwl }}</div>
          <div v-if="item.bs != null" class="margin-top-2 color-65">今日步数：{{ item.bs }}</div>
          <div v-if="item.dy != null" class="margin-top-2 color-65">低压：{{ item.dy }}</div>
          <div v-if="item.gy != null" class="margin-top-2 color-65">高压：{{ item.gy }}</div>
          <div v-if="item.facs != null" class="margin-top-2 color-65">翻转次数：{{ item.facs }}</div>
          <div v-if="item.tw != null" class="margin-top-2 color-65">体温：{{ item.tw }}</div>
          <div v-if="item.xl != null" class="margin-top-2 color-65">心率：{{ item.xl }}</div>
          <div v-if="item.xy != null" class="margin-top-2 color-65">血氧：{{ item.xy }}</div>
          <div v-if="item.realtimeAddress != null" class="margin-top-2 color-65">实时位置：{{
              item.realtimeAddress
            }}
          </div>
        </div>
      </div>
      <div class="flex flex-reverse">
        <div class="close-button" @click="layerSbShow = false">关闭</div>
      </div>
    </el-dialog>

    <el-dialog title="" v-if="layerHdShow" :visible.sync="layerHdShow" width="75.7rem" style="font-size: 1.6rem">
      <div class="flex align-center justify-center flex-column color-65">
        {{ layerParam.oldPersonName }}-{{ layerParam.deviceName }}
      </div>
      <div class="flex align-center justify-center margin-top-2">
        <div
          class="tab-1"
          :class="{'color-65':layerParam.queryType === 1,'color-1f':layerParam.queryType === 2}"
          @click="layerParam.queryType=1;layerParam.pageNum=1;getLayerList();"
        >
          数据展示
        </div>
        <div
          class="tab-2"
          :class="{'color-65':layerParam.queryType === 2,'color-1f':layerParam.queryType === 1}"
          @click="layerParam.queryType=2;layerParam.pageNum=1;getLayerList();"
        >
          告警信息
        </div>
      </div>
      <div class="layer-content-list" v-if="layerParam.queryType === 1" @scroll="layerListScroll">
        <div v-for="(item,index) in layerList" :key="index" class="layer-content-one color-65">
          <div class="color-65 flex align-center justify-between">
            <div>{{ item.currDate }}</div>
            <div>共{{ item.itemList.length }}次</div>
          </div>
          <div class="margin-top-2 layer-line"></div>
          <div v-for="(listItem,listIndex) in item.itemList" :key="listIndex" class="flex align-center margin-top-2">
            <div>{{ listItem.reportTime }}</div>
            <div class="margin-left-2 color-65">{{ listItem.message }}</div>
          </div>
        </div>
      </div>
      <div class="layer-content-list" v-if="layerParam.queryType === 2" @scroll="layerListScroll">
        <div v-for="(item,index) in layerList" :key="index" class="layer-content-one color-65">
          <div class="color-65 flex align-center justify-between">
            <div>{{ item.currDate }}</div>
            <div>共{{ item.itemList.length }}次</div>
          </div>
          <div class="margin-top-2 layer-line"></div>
          <div v-for="(listItem,listIndex) in item.itemList" :key="listIndex" class="flex align-center margin-top-2">
            <div>{{ listItem.reportTime }}</div>
            <div class="margin-left-2 color-e4">{{ listItem.message }}</div>
          </div>
        </div>
      </div>
      <div class="flex flex-reverse">
        <div class="close-button" @click="layerHdShow = false">关闭</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import api from '@/store/API/api'

export default {
  name: 'OldPersonList',
  data() {
    return {
      isShowSearch: false,
      keyword: '',
      list: [],
      searchListLoad: false,
      pageNum: 1,
      layerHdShow: false,
      layerSbShow: false,
      layerSosShow: false,
      layerYgShow: false,
      layerParam: {
        deviceName: '',
        oldPersonId: '',
        oldPersonName: '',
        pageNum: 1,
        pageSize: 10,
        queryType: 1
      },
      layerList: []
    }
  },
  mounted() {
    this.getList(1)
    this.webSocket()
  },
  methods: {
    ...mapActions(['caringPlatformOlderPersonList', 'caringPlatformHdInfo', 'caringPlatformSbInfo', 'caringPlatformSosInfo', 'caringPlatformYgInfo']),
    getList(page) {
      if (this.searchListLoad === true) {
        return
      }
      if (page === 1) {
        this.pageNum = 1
      } else {
        this.pageNum++
      }
      this.searchListLoad = true
      const data = {
        nameOrPhone: this.keyword,
        pageSize: 16,
        pageNum: this.pageNum
      }
      if (this.$route.query.deviceState !== '') {
        data.deviceState = this.$route.query.deviceState
      }
      this.caringPlatformOlderPersonList(data).then(res => {
        if (this.pageNum === 1) {
          res.data.dataList.forEach((item) => {
            item.showArrowDown = true
            item.showStatus = false
            item.SHeart = null
            item.SBreath = null
            item.b = null
            item.sleep = null
            item.leaveBed = null
            item.snore = null
            item.moveBody = null
          })
          this.list = res.data.dataList
        } else {
          this.list = this.list.concat(res.data.dataList)
        }
        this.$nextTick(() => {
          this.list.forEach((item, index) => {
            const textContainer = this.$refs['device-list-' + index][0]
            item.showArrow = textContainer.scrollHeight > textContainer.offsetHeight
            item.showArrowDown = true
          })
          this.$forceUpdate()
        })
        this.searchListLoad = false
      })
    },
    randomNum(n) {
      var res = ''
      for (var i = 0; i < n; i++) {
        res += Math.floor(Math.random() * 10)
      }
      return res
    },
    webSocket() {
      const that = this
      // const socketUrl = "ws://test.lianhuquyl.cn:8182/websocket/test_123456";
      const socketUrl = 'wss://www.lexiangyl.com:8182/yunxin-state-ws/' + sessionStorage.userId + '_' + this.randomNum(6)
      // const socketUrl = "wss://39.107.60.230:8182/websocket/tianshui_123456";
      // const socketUrl = "wss://121.40.217.235:8182/websocket/longting_123456";
      // const socketUrl = "wss://47.96.252.57:8182/websocket/tianshui_123456";
      this.socket = new WebSocket(socketUrl)
      this.socket.onopen = function (res) {
      }
      this.socket.onerror = function (res) {
      }
      this.socket.onmessage = function (msg) {
        const data = JSON.parse(msg.data)
        let list = that.list
        data.forEach((dataItem) => {
          list.forEach((item) => {
            if (item.oldPersonId == dataItem.oldPersonId) {
              item.showStatus = true
              item.SHeart = dataItem.hasOwnProperty('SHeart') ? dataItem.SHeart : null
              item.SBreath = dataItem.hasOwnProperty('SBreath') ? dataItem.SBreath : null
              item.b = dataItem.hasOwnProperty('b') ? dataItem.b : null
              item.sleep = dataItem.hasOwnProperty('sleep') ? dataItem.sleep : null
              item.leaveBed = dataItem.hasOwnProperty('leaveBed') ? dataItem.leaveBed : null
              item.snore = dataItem.hasOwnProperty('snore') ? dataItem.snore : null
              item.moveBody = dataItem.hasOwnProperty('moveBody') ? dataItem.moveBody : null
            }
          })
        })
        console.log(data)
        that.list = list
      }
    },

    /**
     * 左侧搜索滚动到底部
     */
    searchListScroll() {
      this.$nextTick(() => {
        const el = document.querySelector('.content-list')
        const offsetHeight = el.offsetHeight
        el.onscroll = () => {
          const scrollTop = el.scrollTop
          const scrollHeight = el.scrollHeight
          if ((offsetHeight + scrollTop) - scrollHeight >= -1) {
            this.getList(2)
          }
        }
      })
    },
    changeArrow(index) {
      this.list[index].showArrowDown = !this.list[index].showArrowDown
      this.$forceUpdate()
    },
    exportData() {
      let url = process.env.NODE_ENV === 'development' ? '/api' : process.env.VUE_APP_BASEURL
      url += api.caringPlatformOlderPersonListExport

      const myObj = {
        method: 'post',
        url,
        fileName: '',
        params: {}
      }
      this.$tools.exportMethodNoFileName(myObj)
    },

    showLayer(oldPersonName, oldPersonId, deviceName) {
      this.layerParam.oldPersonName = oldPersonName
      this.layerParam.oldPersonId = oldPersonId
      this.layerParam.deviceName = deviceName
      this.layerParam.queryType = 1
      this.layerParam.pageNum = 1
      this.getLayerList()
    },
    getLayerList() {
      console.log(this.layerParam.deviceName);
      switch (this.layerParam.deviceName) {
        case 'NB烟感报警器':
          this.caringPlatformYgInfo(this.layerParam).then(res => {
            if (this.layerParam.pageNum === 1) {
              this.layerList = res.data.dataList
            } else {
              this.layerList = this.layerList.concat(res.data.dataList)
            }
            this.layerParam.pageNum++
            this.layerYgShow = true
          })
          break
        case '紧急呼叫器':
          this.caringPlatformSosInfo(this.layerParam).then(res => {
            if (this.layerParam.pageNum === 1) {
              this.layerList = res.data.dataList
            } else {
              this.layerList = this.layerList.concat(res.data.dataList)
            }
            this.layerParam.pageNum++
            this.layerSosShow = true
          })
          break
        case '定位手表':
          this.caringPlatformSbInfo(this.layerParam).then(res => {
            if (this.layerParam.pageNum === 1) {
              this.layerList = res.data.dataList
            } else {
              this.layerList = this.layerList.concat(res.data.dataList)
            }
            this.layerParam.pageNum++
            this.layerSbShow = true
          })
          break
        case '人体感应探测器':
          this.caringPlatformHdInfo(this.layerParam).then(res => {
            if (this.layerParam.pageNum === 1) {
              this.layerList = res.data.dataList
            } else {
              this.layerList = this.layerList.concat(res.data.dataList)
            }
            this.layerParam.pageNum++
            this.layerHdShow = true
          })
          break
        case '智能网关':
          this.$router.push({
            path: '/smartElderlyPlat/weekReport',
            query: { id: this.layerParam.oldPersonId, alarmSource: 2 }
          })
          break
        case '智能床垫':
          this.$router.push({
            path: '/smartElderlyPlat/weekReport',
            query: { id: this.layerParam.oldPersonId, alarmSource: 2 }
          })
          break
      }
    },

    /**
     * 左侧搜索滚动到底部
     */
    layerListScroll() {
      this.$nextTick(() => {
        const el = document.querySelector('.layer-content-list')
        const offsetHeight = el.offsetHeight
        el.onscroll = () => {
          const scrollTop = el.scrollTop
          const scrollHeight = el.scrollHeight
          if ((offsetHeight + scrollTop) - scrollHeight >= -1) {
            this.getLayerList()
          }
        }
      })
    }
  }
}
</script>

<style scoped>
.back {
  width: calc(100% - 8.6rem);
  height: calc(100% - 14rem);
  background: #00060C;
  background-image: url("../../../assets/images/smartElderly/list-back.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 7rem 4.3rem;
}

.return-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.3rem;
  height: 4.9rem;
  background: #000E18;
  border: 0.1px solid #3C62C0;
  border-radius: 0.4rem;
}

.export-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8rem;
  height: 4.9rem;
  background: #000E18;
  color: #FFFFFF;
  border: 0.1px solid #3C62C0;
  border-radius: 0.4rem;
}

.return {
  width: 1rem;
  height: 1.8rem;
}

.rotate {
  transform: rotate(180deg);
}

.input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 0.9rem;
  padding: 0.6rem 0.6rem 0.1rem 2.6rem;
  width: 32.5rem;
  background: #000E18;
  border: 0.1px solid #3C62C0;
  border-radius: 0.4rem;
}

.search {
  width: 2.9rem;
  height: 2.8rem;
}

/deep/ input, /deep/ input:hover, /deep/ input:focus {
  background: #001428;
  border: none;
  border-radius: 8px;
  color: #65C6E7;
  height: 4.4rem;
  line-height: 4.4rem;
}

/deep/ input::-moz-placeholder {
  color: #1F3367;
  font-size: 1.6rem;
}

/deep/ input::-ms-input-placeholder {
  color: #1F3367;
  font-size: 1.6rem;
}

/deep/ input::-webkit-input-placeholder {
  color: #1F3367;
  font-size: 1.6rem;
}

.content-list {
  width: 180rem;
  height: 87rem;
  overflow-y: auto;
}

.content-one {
  width: calc((100% - 8rem) / 4);
  min-height: 28.7rem;
  margin: 1.9rem 1.7rem 0 0;
  background: rgba(0, 27, 54, 0.5);
  border: 0.1px solid #3C62C0;
  border-radius: 0.4rem;
}

.photo-url {
  margin: 1.9rem 1.7rem;
  width: 7.2rem;
  height: 10rem;
}

.name {
  margin-top: 1.9rem;
  color: #14CA65;
  font-size: 1.8rem;
  margin-bottom: 0.8rem;
}

.desc {
  font-size: 1.6rem;
  color: #42A5C6;
  margin-top: 0.5rem;
}

.line {
  margin-top: 2rem;
  margin-left: 1.7rem;
  width: calc(100% - 3.4rem);
  height: 0.1rem;
  background: #3C62C0;
}

.zhineng {
  margin: 1.6rem 0 0 1.7rem;
  font-size: 1.8rem;
  color: #00ECFB;
}

.device-list {
  margin: 0.1rem 1.7rem 1.2rem 1.7rem;
}

.device-one {
  font-size: 1.6rem;
  text-decoration: underline;
  margin: 1.6rem 1.2rem 0 0;
}

.device-status-1 {
  color: #13C362;
}

.device-status-2 {
  color: #EC5354;
}

.text-cut-one {
  line-height: 2.2rem;
  height: 4rem;
  overflow: hidden;
}

.height-auto {
  height: auto !important;
}

.close-button {
  width: 8.9rem;
  height: 4.9rem;
  background: #001B36;
  border: 0.1px solid #3C62C0;
  border-radius: 0.8rem;
  line-height: 4.9rem;
  text-align: center;
  margin-top: 2rem;
  color: #65C6E7;
}

/deep/ .btn-prev, /deep/ .btn-prev:disabled {
  background-color: rgba(255, 255, 255, 0);
  color: #3C62C0 !important;
}

/deep/ .btn-next, /deep/ button:disabled {
  background-color: rgba(255, 255, 255, 0);
  color: #3C62C0;
}

/deep/ .el-pager li {
  line-height: 3.41rem !important;
}

/deep/ .el-pager .number.active {
  width: 3.52rem;
  height: 3.52rem;
  background: #18C3E8;
  border-radius: 2px;
  color: #031326;
  font-size: 1.65rem;
  margin-right: 2.2rem;
  border: none;
}

/deep/ .el-pager .number, /deep/ .el-pager .more {
  width: 3.52rem;
  height: 3.52rem;
  background: rgba(0, 45, 86, 0.5);
  border: 1px solid #3C62C0;
  border-radius: 2px;
  color: #13C0FB;
  font-size: 1.65rem;
  margin-right: 2.2rem;
}

/deep/ .el-dialog {
  border: 1px solid #3C62C0;
  border-bottom: none;
  border-radius: 8px 8px 0 0;
  background: #001B36;
}

/deep/ .el-dialog__title, /deep/ .el-dialog__headerbtn > .el-icon-close {
  color: #65C6E7 !important;
}

/deep/ .el-dialog .el-dialog__header {
  text-align: center;
}

/deep/ .el-dialog__body {
  border-bottom: 1px solid #3C62C0;
}

.color-65 {
  color: #65C6E7;
}

.color-ec {
  color: #EC5354;
}

.layer-content-list {
  height: 50rem;
  margin: 0 0 0 0.5rem;
  overflow-y: auto;
  padding-right: 1rem;
}

.layer-content-one {
  margin-top: 2.4rem;
  width: calc(100% - 6.6rem);
  background: #001428;
  border: 0.1px solid #3C62C0;
  border-radius: 0.8rem;
  padding: 2rem 3.2rem;
}

.layer-line {
  width: 100%;
  height: 0.1rem;
  background: #3C62C0;
}

.margin-top-2 {
  margin-top: 2rem;
}

.margin-left-2 {
  margin-left: 2.5rem;
}

/*滚动条的轨道*/
::-webkit-scrollbar-track {
  background-color: #001B36;
}

/*滚动条里面的小方块，能向上向下移动*/
::-webkit-scrollbar-thumb {
  background-color: #3C62C0;
  border-radius: 5px;
  height: 5px;
  border: none;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #3C62C0;
}

::-webkit-scrollbar-thumb:active {
  background-color: #3C62C0;
}

/*边角，即两个滚动条的交汇处*/
::-webkit-scrollbar-corner {
  background-color: #ffffff;
}

.color-13 {
  color: #13C362;
}

.color-1f {
  color: #1F3367;
}

.tab-1 {
  width: calc(50% - 9.2px);
  height: 4.1rem;
  line-height: 4.1rem;
  text-align: center;
  background: #001428;
  border: 0.1px solid #3C62C0;
  border-radius: 0.8rem 0rem 0rem 0.8rem;
}

.tab-2 {
  width: calc(50% - 9.2px);
  height: 4.1rem;
  line-height: 4.1rem;
  text-align: center;
  background: #001428;
  border: 0.1px solid #3C62C0;
  border-radius: 0rem 0.8rem 0.8rem 0rem;
}

.color-e4 {
  color: #E45052;
}

.zaichuang {
  width: 2.8rem;
  height: 1.4rem;
  margin-left: 1rem;
  padding: 0.2rem 0.8rem;
  line-height: 1.4rem;
  text-align: center;
  font-size: 1.4rem;
  font-weight: bolder;
  color: #00E0EF;
  border-radius: 0.4rem;
  border: 0.2rem solid #00E0EF;
}

.lichuang {
  width: 2.8rem;
  height: 1.4rem;
  margin-left: 1rem;
  padding: 0.2rem 0.8rem;
  line-height: 1.4rem;
  text-align: center;
  font-size: 1.4rem;
  font-weight: bolder;
  color: #01172E;
  background: #E04F51;
  border-radius: 0.4rem;
  border: 0 solid #E04F51;
}
</style>
